<template>
  <div class="staff-statistic-layout">
    <v-echarts :options="lineChartOption" ref="line-chart" style="width: 100%; height: 300px" />
    <div class="row">
      <div class="left">
        <v-echarts :options="barChartOption" style="width: 100%; height: 300px" />
      </div>
      <div class="right">
        <JYNumberDisplayer :value="189" label="员工总数" icon="#icon-depart2" />
      </div>
    </div>
  </div>
</template>

<script>
import Echarts from 'vue-echarts'
import 'echarts'
import util from '@/util/util'
export default {
  name: 'StuffStatistic',
  components: {
    'v-echarts': Echarts
  },
  mounted() {
    const echartsInstance = this.$refs['line-chart']
    const resizeFn = util.throttle(() => {
      echartsInstance.resize()
    }, 500)
    window.onresize = resizeFn
  },
  data() {
    return {
      lineChartOption: {
        title: {
          text: '员工登录活跃走势图'
        },
        grid: {
          height: 200,
          x: 60, //默认是80px
          y: 40, //默认是60px
          x2: 60 //默认80px
          // y2: 30 //默认60px
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['2019-06-10', '2019-06-11', '2019-06-12', '2019-06-13', '2019-06-14', '2019-06-15', '2019-06-16']
        },
        yAxis: {
          type: 'value'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        dataZoom: [
          {
            type: 'slider',
            xAxisIndex: 0,
            filterMode: 'empty'
          },
          {
            type: 'slider',
            yAxisIndex: 0,
            filterMode: 'empty'
          },
          {
            type: 'inside',
            xAxisIndex: 0,
            filterMode: 'empty'
          },
          {
            type: 'inside',
            yAxisIndex: 0,
            filterMode: 'empty'
          }
        ],
        series: [
          {
            data: [20, 932, 90, 93, 129, 133, 132],
            type: 'line',
            smooth: true,
            areaStyle: {
              color: '#AFD5C3'
            },
            lineStyle: {
              color: '#91C7AE'
            }
          }
        ]
      },
      barChartOption: {
        title: {
          text: '访问终端来源类型',
          x: 'center'
        },
        legend: {
          orient: 'vertical',
          x: 'right',
          data: ['Chrome', '360', 'FireFox', 'Safari', 'IE', '其他'],
          itemGap: 20,
          top: 'middle'
        },
        color: ['#F2637B', '#975FE4', '#3AA0FF', '#36CBCB', '#4DCB73', '#FAD337'],
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: ['55%', '70%'],
            center: ['50%', '50%'],
            data: [
              { value: 1548, name: 'Chrome' },
              { value: 234, name: 'FireFox' },
              { value: 135, name: 'Safari' },
              { value: 310, name: '360' },
              { value: 335, name: 'IE' },
              { value: 150, name: '其他' }
            ],
            labelLine: {
              normal: {
                show: false
              }
            },
            label: {
              normal: {
                show: false,
                position: 'center'
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 20,
                  fontWeight: 'bold'
                }
              }
            },
            itemStyle: {
              normal: {
                borderWidth: 3,
                borderColor: 'white'
              },
              emphasis: {
                shadowBlur: 2,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../theme/mixin';
.row {
  display: flex;
  margin-top: 30px;
}
.left {
  width: 400px;
}
.right {
  flex: 1;
}
</style>
