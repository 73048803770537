<template>
  <div class="study-data">
    <Table :data="studyData" :columns="columns">
      <template slot="progress" slot-scope="{ row }">
        <Progress :percent="~~Math.round(row.sectionProgress * 100)" />
      </template>
    </Table>
    <Page :total="total" size="small" @on-change="handlePageChange" />
  </div>
</template>

<script>
import CourseAPI from '@/api/course'
export default {
  name: 'StudyData',
  data() {
    return {
      total: 0,
      currentPage: 1,
      pageSize: 10,
      studyData: [{}],
      columns: [
        {
          key: 'realName',
          title: '姓名'
        },
        {
          key: 'phone',
          title: '手机号'
        },
        {
          key: 'courseCounts',
          title: '学习课程数'
        },
        {
          key: 'sectionCounts',
          title: '总学习节数'
        },
        {
          key: 'completedSection',
          title: '已学节数'
        },
        {
          slot: 'progress',
          title: '学习进度'
        }
      ]
    }
  },
  created() {
    this.requestStudyDataList()
  },
  methods: {
    async requestStudyDataList() {
      const { res } = await CourseAPI.getStudyStatisticList(this.currentPage - 1, this.pageSize)
      this.total = res.total
      this.studyData = res.data
    },
    handlePageChange(newPage) {
      this.currentPage = newPage
    }
  },
  watch: {
    currentPage() {
      this.requestStudyDataList()
    }
  }
}
</script>

<style lang="less" scoped></style>
